import { useEffect, useState } from 'react';
import { ParsedUrlQuery } from 'querystring';
import { useRouter } from 'next/router';
import { IQueryString, defaultQueryString } from '../redux/slices/room';

const formatQueryString = (value: string) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
};

interface IQueryParams {
  [k: string]: string | boolean;
}

export const useGetQueryParams = () => {
  const { isReady, query } = useRouter();
  const [normalizedQuery, setNormalizedQuery] =
    useState<IQueryString>(defaultQueryString);

  useEffect(() => {
    if (isReady) {
      const normalizeQueryParams = (queryParams: ParsedUrlQuery) => {
        const normalized: IQueryParams = { ...defaultQueryString };
        Object.keys(queryParams).forEach(key => {
          normalized[key.toLowerCase()] = formatQueryString(
            queryParams[key] as string,
          );
        });
        return normalized;
      };

      setNormalizedQuery(
        normalizeQueryParams(query) as unknown as IQueryString,
      );
    }
  }, [query, isReady]);

  return normalizedQuery;
};
