import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Conference, LiveKitRoomStreamGo } from '../components';
import { useGetQueryParams } from '../hooks/useGetQueryParams';
import { addWebcamToken } from '../redux/slices/auth';
import { setBaseUrl } from '../redux/slices/config';
import { setQueryString } from '../redux/slices/room';

function fixToken(token: string) {
  let decodedToken = decodeURIComponent(token);
  decodedToken = decodedToken.replace(/ /g, '+');

  while (decodedToken.length % 4 !== 0) {
    decodedToken += '=';
  }

  return encodeURIComponent(decodedToken);
}

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBaseUrl(process.env.NEXT_PUBLIC_STREAMGO_API as string));
  }, [dispatch]);

  const queryParams = useGetQueryParams();

  useEffect(() => {
    dispatch(setQueryString(queryParams));
  }, [dispatch, queryParams]);

  useEffect(() => {
    if (queryParams.token) {
      dispatch(addWebcamToken(fixToken(queryParams.token)));
    }
  }, [dispatch, queryParams.token]);

  return (
    <LiveKitRoomStreamGo>
      <Conference />
    </LiveKitRoomStreamGo>
  );
};

export default Index;
